







































































import { prefix } from '@/config/global';
import { Vue, Component, Watch } from 'vue-property-decorator';
import  { GridParams }   from '@/types/store';
import  { gridDepressionData }  from '@/services/api';
import rankFilter from '@/components/rankFilter/index.vue';
import rankTable from '@/components/rankTable/index.vue';
import { getMapCenter, initMap, drawMall, clearMall, processDataByStartEnd, zoomUp, zoomDown, processDepressionData } from '@/components/map/map';
import { Grid } from '@/components/map/grid';
import gradientCard from '@/components/GradientCard/index.vue';
import spinCard from '@/components/SpinCard/index.vue';
import { computePassengerFlowProfile, getContainer, getMax, getMin, processBeforeDateByDuration } from '@/components';
import countUpFn from '@/utils/countUp';
import helpCircleFilled from '@/components/HelpCircleFilled/index.vue';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/naming-convention */
@Component({
  components: {
    spinCard,
    rankFilter,
    rankTable,
    gradientCard,
    helpCircleFilled,
  },
})


export default class TouristSource extends Vue {
  $dayjs: any;
  prefix: string = prefix;
  $stores: any;
  spinShow = true;
  mallMarker!: any;
  tableWh = 400;
  table= [{}];
  res: any;
  data: any= [{}];
  map: any;
  filterValue: number[]=[0, 100];
  duration = '';
  mallId = '';
  date = '';
  dates = '';
  boundary = '';
  center = '';
  params!: GridParams;
  rowActive = 0;
  initZom = 13;
  step = 0.01;
  max = 100;
  min = 0;
  gridColor = ['rgba(43,50,153, .7)', 'rgba(46,63,176, .7)', 'rgba(50,85,199, .7)', 'rgba(44,99,217, .7)', 'rgba(43,115,224, .7)', 'rgba(41,132,230, .7)', 'rgba(41,145,230, .7)', 'rgba(46,178,255, .7)', 'rgba(91,201,255, .7)'].reverse();
  gridColor2 = ['rgba(255,255,255,.1)'];
  grid: any;
  title = '本市覆盖排名';
  type = 0;
  text = '1平方公里';
  singleColor = 'blue';
  titleDisplay = true;
  gradientWidth = 22;
  gradientList = [
    {
      id: 1,
      color: this.gridColor[0],
      width: this.gradientWidth,
    },
    {
      id: 2,
      color: this.gridColor[1],
      width: this.gradientWidth,
    },
    {
      id: 3,
      color: this.gridColor[2],
      width: this.gradientWidth,
    },
    {
      id: 4,
      color: this.gridColor[3],
      width: this.gradientWidth,
    },
  ];
  gradientValue = [
    {
      id: 1,
      num: 1,
    },
    {
      id: 2,
      num: 2,
    },
    {
      id: 3,
      num: 3,
    },
  ];
  gridColorObj1 = {
    color: this.gridColor,
    top0Color: this.gridColor[0],
    top1Color: 'rgba(254,12,46, .6)',
    strokeStyle: 'rgba(255,255,255,.6)',
    lineWidth: 1,
    textColor: '#ffffff',
  };
  color = 'rgba(255,255,255,.1)';
  gridColorObj2 = {
    color: this.gridColor2,
    top0Color: this.color,
    top1Color: this.color,
    strokeStyle: 'rgba(80,128,215,1)',
    lineWidth: 2,
    textColor: 'black',
  };
  gridColorObj = this.gridColorObj1;

  mapHeight = '0';
  gridType = 0;
  gridClickType = 0;
  gridFunctionStatus = 1;
  gridFunctionOffStatus = 0;
  $beaconAction: any;
  gridInfo = {
    cityNum: 0,
    cityNumPercent: '0',
    gridArea: 0,
    gridAreaPercent: '0',
    gridNum: 0,
    gridNumPercent: '0',
    gridPop: 0,
    gridPopPercent: '0',
    depressionArea: 0,
    depressionNum: 0,
    lowNum: 0,
  };
  allData: any = [];
  titleInfo1 = '由于数据隐私风险，客流过低的区域网格未做可视化展示，未展示区域客流数为';
  titleInfo = '';
  mounted(): void {
    const container = getContainer();
    this.mapHeight = `${container}px`;
    this.map = initMap('map-container', this.initZom, this.center);
    const { pageConfig } = this.$store.state;
    this.filterValue = pageConfig.businessFilterValue;
  }

  pageHandlers(): void{
    this.spinShow = true;
    setTimeout(() => {
      this.pageHandler(this.gridType, this.gridColorObj);
    }, 0);
  }

  getTableByType(): any {
    const table = [
      {
        title: '排名',
        key: 'cover_rank',
        sortable: true,
      },
      {
        title: '输送客流',
        key: 'num',
        sortable: true,
      },
      {
        title: '区域人口',
        key: 'pop',
        sortable: true,
      },
      {
        title: '渗透率(%)',
        key: 'cover',
        sortable: true,
      },
    ];
    return table;
  }

  getClassNameByRatio(ratio: string): string {
    return parseFloat(ratio) >= 0 ? 't-green' : 't-red';
  }

  processRatio(ratio: string): string {
    if (isNaN(parseFloat(ratio)) || parseFloat(ratio) === Infinity) {
      return '+0%';
    }
    return parseFloat(ratio) >= 0 ? `+${ratio}` : ratio;
  }

  async depressionDataHandler(res: any): Promise<void> {
    const { avg, data } = res;
    const { area, sum } = processDepressionData(0, avg, data || []);
    this.gridInfo.depressionArea = area;
    this.gridInfo.depressionNum = sum;
  }

  async comparedYesterday(res: any): Promise<void> {
    // 今天数据
    const { city_num: cityNum, area, sum, pop } = res;
    const dateObj = processBeforeDateByDuration(this.date, this.duration);
    const { dataDate } = dateObj;
    // 获取昨日数据进行对比
    const params = {
      mall_id: this.mallId,
      date: dataDate,
      duration: this.duration,
      start_percent: 0,
      end_percent: 100,
      type: 1,
    };
    const yRes = await this.getGridDepressionDataFn(params, false);
    const { city_num: yCityNum, area: yArea, sum: ySum, pop: yPop } = yRes;

    const cityRatio = computePassengerFlowProfile(
      String(yCityNum),
      String(cityNum),
      3,
    );

    const areaRatio = computePassengerFlowProfile(
      String(yArea),
      String(area),
      3,
    );

    const sumRatio = computePassengerFlowProfile(
      String(ySum),
      String(sum),
      3,
    );

    const popRatio = computePassengerFlowProfile(
      String(yPop),
      String(pop),
      3,
    );

    this.gridInfo.cityNumPercent = this.processRatio(cityRatio);
    this.gridInfo.gridAreaPercent = this.processRatio(areaRatio);
    this.gridInfo.gridNumPercent = this.processRatio(sumRatio);
    this.gridInfo.gridPopPercent = this.processRatio(popRatio);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async pageHandler(type: number, gridColorObj?: any): Promise<void> {
    this.$stores.global.setDownAble(false); // 数据全部加载完全后才允许下载
    const { mall, pageConfig } = this.$store.state;
    const {
      boundary,
      center,
      date,
      mallId,
      duration,
      dates,
    } = mall;
    this.filterValue = pageConfig.businessFilterValue;

    this.boundary = boundary;
    this.center = center;
    this.date = date;
    this.dates = dates;
    this.mallId = mallId;
    this.duration = duration;
    if (this.center) {
      const center = getMapCenter(this.center);
      this.map.setCenter(center);
      this.map.panBy(198, 0);
      clearMall(this.mallMarker);
      this.mallMarker = drawMall(this.map, center);
    }
    this.table = this.getTableByType();

    if (!this.data) {
      this.data = [];
      this.allData = [];
    }
    if (type === 0) {
      this.params = {
        mall_id: this.mallId,
        date: this.date,
        duration: this.duration,
        start_percent: 0,
        end_percent: 100,
        type: 1,
      };
      this.res = await this.getGridDepressionDataFn(this.params);
      this.data = this.res.data || [];
      this.allData = this.data;
      const { sum, area, city_num: cityNum, pop, low_num: lowNum } = this.res;
      this.gridInfo.cityNum = cityNum;
      this.gridInfo.gridArea = area;
      this.gridInfo.gridNum = sum;
      this.gridInfo.gridPop = pop;
      this.gridInfo.lowNum = lowNum;
      this.titleInfo = `${this.titleInfo1}(${this.gridInfo.lowNum}) 人`;
      //  countUpFn 填充 city-source grid-area  grid-num grid-pop depression-area depression-num
      countUpFn('city-source', cityNum);
      // countUpFn('grid-area', area);
      countUpFn('grid-num', sum);
      countUpFn('grid-pop', pop);
      // countUpFn('depression-area', area);
      // countUpFn('depression-num', sum);
      // 洼地策略 ： 洼地最小值到洼地平均值 = 洼地渗透率
      this.depressionDataHandler(this.res);

      // 较昨日数据执行函数
      this.comparedYesterday(this.res);
    }

    if (type === 1 || this.filterValue[0] !== 0 || this.filterValue[1] !== 100) {
      const data = this.allData.slice(1);
      const data0 = this.allData[0];
      // const { data: dataAry } = processDataByStartEnd(this.filterValue[0], this.filterValue[1], data);
      this.data = processDataByStartEnd(this.filterValue[0], this.filterValue[1], data);
      this.data.unshift(data0);

      // 总概览 暂时不需要通过筛选的动态值
      // this.res.sum = gridNum;
      // this.res.pop = gridPop;
      // this.res.area = dataAry.length;
    }

    this.max = getMax(this.data, 'cover');
    this.min = getMin(this.data, 'cover');

    this.data.forEach((item: any, index: number) => {
      (this.data[index] as any).index = index;
    });
    this.grid = new (Grid as any)({
      map: this.map,
      data: this.data,
      color: gridColorObj.color,
      weightFiled: 'cover',
      weightType: 2,
      rankFiled: 'rank',
      top0Color: gridColorObj.top0Color,
      top1Color: gridColorObj.top1Color,
      strokeStyle: gridColorObj.strokeStyle,
      lineWidth: gridColorObj.lineWidth,
      textColor: gridColorObj.textColor,
    });

    this.grid.gridClick((item: any) => {
      const { cover_rank: coverRank } = item;
      this.rowActive = coverRank;
      (this.$refs.tables as any).tableClickHandler(item, 1);
    });
    this.grid.gridMove();

    this.gradientList = [];
    let index = 0;
    this.gridColor.forEach((item: any) => {
      index = index + 1;
      const obj = {
        id: index,
        color: item,
        width: this.gradientWidth,
      };
      this.gradientList.push(obj);
    });
    const valueCenter = Math.round(this.max / 2 * 100) / 100;
    this.gradientValue = [
      {
        id: 1,
        num: this.min,
      },
      {
        id: 2,
        num: valueCenter,
      },
      {
        id: 3,
        num: this.max,
      },
    ];
    this.spinShow = false;
    this.$stores.global.setDownAble(true); // 数据全部加载完全后才允许下载
  }
  @Watch('$store.state.mall', { deep: true })
  changeMall(): void {
    this.gridColorObj = this.gridColorObj1;
    this.gridType = 0;
    this.pageHandlers();
  }
  @Watch('$store.state.global.downloadStatus')
  changeExport(): void {
    const dateObj = {
      d: this.date,
      xw: `${this.$dayjs(this.date).subtract(6, 'day')
        .format('YYYY-MM-DD')}~${this.date}`,
      xm: this.$dayjs(this.date).format('YYYY-MM'),
    };

    this.res.data.forEach((i: any) => {
      // eslint-disable-next-line no-param-reassign
      i.date = (dateObj as any)[this.duration];
    });
    const datas = [];
    const status = this.$store.state.global.downloadStatus;
    if (status === true) {
      datas.push({
        sheetData: this.data ? this.data : [],
        sheetName: '本市覆盖排名',
        sheetFilter: [
          'date',
          'rank',
          'num',
          'pop',
          'cover',
        ],
        sheetMultiHeader: [
          [`数据项:商圈覆盖-${this.title}`],
        ],
        sheetHeader: [
          '日期',
          '排名',
          '输送客流',
          '区域人口',
          '渗透率',
        ],
        columnWidths: [8, 12, 12, 12, 12, 12, 12, 12, 12],
      });
      datas.push({
        sheetData: this.res ? [this.res] : [],
        sheetName: '本市客流人数',
        sheetFilter: [
        ],
        sheetHeader: [
          '本市客流人数',
          this.res.sum,
        ],
        columnWidths: [8, 12],
      });
      this.$root.$data.event.$emit('getDataS', datas);
    }
  }
  async getGridDepressionDataFn(params: GridParams, showInfoWindow = true): Promise<any> {
    const data = await gridDepressionData(params, showInfoWindow);
    return data;
  }

  sliderChange(val: number[]): void {
    // 进度条数据变化返回
    this.filterValue = val;
    this.$stores.pageConfig.setBusinessFilterValue(val);
    this.gridType = 1;
    this.buriedPointHandler('city_coverage_depression_click');
    this.pageHandlers();
  }

  buriedPointHandler(buriedPoint: string, params?: any): void{
    this.$beaconAction.onUserAction(buriedPoint, params);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  rowClick(val: any): void{
    // 列表点击事件返回数据
    if (this.rowActive === val.index) {
      return;
    }
    this.rowActive = val.index;
    const center = this.grid.getGridCenter(val);
    this.grid.polygonHandler(val);
    const { lat, lng } = center;
    const centers = new (window as any).qq.maps.LatLng(lat, lng);
    this.map.setCenter(centers);
    this.map.panBy(198, 0);
  }
  zoomUp(): void {
    zoomUp(this.map);
  }

  zoomDown(): void {
    zoomDown(this.map);
  }

  mallPostionClickHandler(): void{
    const center = getMapCenter(this.center);
    this.map.panTo(center);
    this.map.panBy(198, 0);
  }

  gridFunctionClickHandler(): void{
    if (this.gridClickType === 0) {
      this.gridFunctionStatus = 0;
      this.gridFunctionOffStatus = 1;
      this.gridClickType = 1;
      this.gridColorObj = this.gridColorObj2;
    } else {
      this.gridFunctionStatus = 1;
      this.gridFunctionOffStatus = 0;
      this.gridClickType = 0;
      this.gridColorObj = this.gridColorObj1;
    }
    this.grid = new (Grid as any)({
      map: this.map,
      data: this.data,
      color: this.gridColorObj.color,
      weightFiled: 'cover',
      weightType: 2,
      rankFiled: 'rank',
      top0Color: this.gridColorObj.top0Color,
      top1Color: this.gridColorObj.top1Color,
      strokeStyle: this.gridColorObj.strokeStyle,
      lineWidth: this.gridColorObj.lineWidth,
      textColor: this.gridColorObj.textColor,
    });
    this.grid.gridClick((item: any) => {
      const { cover_rank: coverRank } = item;
      this.rowActive = coverRank;
      (this.$refs.tables as any).tableClickHandler(item, 1);
    });
    this.grid.gridMove();
    const item = this.data[this.rowActive];
    setTimeout(() => {
      this.grid.polygonHandler(item);
    }, 0);
  }
}
